@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@press-cls: ~'@{ra-prefix}-press';
@press-logo-cls: ~'@{press-cls}-logo';

:export {
  pressCls: @press-cls;
  pressLogoCls: @press-logo-cls;
}

.@{press-cls} {
  .@{press-logo-cls} {
    text-align: center;

    .ra-link {
      display: inline-block;
      height: 100%;
      margin: 0 15px;

      img {
        width: 100% !important;
        height: auto !important;
        vertical-align: middle;
      }
    }
  }
}
